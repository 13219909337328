<template>  
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true" :dismissable="false" :showCloseIcon="false" @hide="closeForm" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'"> 
            <form @submit.prevent="login" @keydown="form.onKeydown($event)" enctype="multipart/form-data"> 
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0">Manage {{module_name}}</h3> 
                        <p>You can add or update {{module_name}} here. </p>
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">  
                        <div class="col-12 lg:col-12 sm:col-12 md:col-12">
                            <div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Name</label>
                                    <InputText id="name" placeholder="Name" v-model="form.name"  type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="name" /> 
                                </div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2" v-if="checkPermission(16,'dropdown')"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Company</label>
                                    <Dropdown CompanyLoading :filter="true" :showClear="true"  v-model="form.company_id" :options="companies" optionLabel="name" optionValue="id"  :placeholder="CompanyLoading ? 'Loading...' : 'Select Company'" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="company_id" />
                                </div>   
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>Status</label>
                                    <Dropdown v-model="form.status" :options="RecordStatuses" optionLabel="name" optionValue="id" placeholder="Select Status" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="status" />
                                </div>  
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label>Description</label>
                                    <Textarea placeholder="Description" v-model="form.description" rows="5" cols="30" class="full-width" />
                                    <HasError class="p-error" :form="form" field="description" />
                                </div>  
                            </div>  
                        </div>  
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <Button type="submit" label="Save" :loading="isSaving" icon="pi pi-check" @click="save()" class="p-button-raised p-button-lg p-button-success mr-2"/>
                                <Button type="submit" label="Save and Close" :loading="isSaving" icon="pi pi-check" @click="saveAndClose=true, save()" class="p-button-raised p-button-lg p-button-info mr-2"/>
                                <Button type="button" label="Reset" icon="pi pi-refresh" class="p-right p-button-raised p-button-lg p-button-warning mr-2" @click="resetForm()"/>
                            </template>
                            <template v-slot:end> 
                                <Button type="button" label="Cancel" icon="pi pi-times" class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeForm"/>
                            </template> 
                        </Toolbar>   
                    </div>
                </div> 
            </form>
        </Sidebar> 
    </BlockUI>
    
</template>
<script> 
export default {
    data() {
			return {  
                form: new this.Form({ 
                    id: '', 
                    company_id: 0, 
                    name: '',   
                    status:1, 
                    description: '',    
                }),   
                CompanyLoading:false,
                module_name:'Product Category',  
                isSaving:false,  
                saveAndClose:false, 
                recId:0,
                loadingComponent:true,
                formVisible:false, 
                companies:[], 
			}
		},   
        props:{
            showForm : {
                type : Boolean,
                required : true,
            },
            recordId :{
                type : Number,
            },
        },
		created() {   
		},
        computed: { 
        },
		mounted() {  
            this.resetForm();
            this.formVisible = this.showForm; 
            this.recId = this.recordId;   
            this.getAllRequestData();  
		},
		methods: {   
             getAllRequestData(){   
                this.$axios.all([this.updateRecord()])   
                .then(() => {  
                    this.getCompanies();   
                }) 
                .then(() => { 
                    this.blockComponent = false;     
                }).catch(() => {    
                //    this.getAllRequestData();
                })
                .finally(() => {  
                }); 
            },
            async getCompanies(){ 
                var Vthis = this;
                Vthis.CompanyLoading = true;
                await this.axios.post('getAllCompanies',{dropdown:true})
                .then( (response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        this.companies = response.data;   
                        if(this.form.company_id==0){
                            this.form.company_id = response.data[0].id;
                        }
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => { 
                    Vthis.CompanyLoading = false;
                })  
            }, 
              
            resetForm(){
                this.form.reset();
            },
            async save(){   
                var Vthis = this; 
                this.isSaving = true;
                await this.form
                .post(Vthis.$baseurl+'api/saveProductCategory', Vthis.form)
                .then((response) => { 
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        if(!this.recId){
                            //  this.getRecords();
                        }
                        // this.getRecords();
                        if(this.saveAndClose){   
                            setTimeout( () => {this.closeForm();},1000);
                            this.saveAndClose = false;
                            // this.closeForm();
                        }   
                        Vthis.showMessage('Saved successfully','success',); 
                    }
                })
                .catch((error) => {   
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.isSaving = false; 
                }) 
            }, 
            loadForm(){
                this.resetForm(); 
            },
            closeForm(){
                this.$emit('formClose',true); 
            },
             
            async updateRecord(){ 
                if(this.recId > 0){
                    var Vthis = this;
                    this.loading = true;
                    await this.axios.post('getAllProductCategories',{id:this.recId})
                    .then( (response) => {    
                        let responseStatus = this.printResponseResult(response,false); 
                        if(responseStatus == 200){ 
                            response.data[0].status = response.data[0].status == 'Blocked' ? 0 : 1;
                            Vthis.form.fill(response.data[0]); 
                        }
                    })
                    .catch((error) => {    
                        this.printResponseResult(error);
                    })
                    .finally(() => { 
                        Vthis.loading = false;
                    })  
                }
            },   
            
            
		},
        watch : {
             
        }
}
</script>
 
